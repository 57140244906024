import { useCallback, useEffect, useState } from "react";
import { UNIFI_API_ENDPOINT } from "../../constants/endpoints";
import { getAndThen } from "../../utils/fetchAndThen";
import { UnifiSiteType, UnifiSitesType, UnifiDeviceType, UnifiDevicesType } from "../../types";
import CSS from "csstype";
import UnifiSite from "./UnifiSite";

interface SitesType {
  [index: string]: string;
}

const unifiSitesStyle: CSS.Properties = {
  clear: "both",
  textAlign: "center",
  width: "100%"
}

const UnifiSites = (props: UnifiSitesType) => {
  const [devices, setDevices] = useState<UnifiDevicesType>();
  const [sites, setSites] = useState<SitesType>();

  const getSites = useCallback(() => {
    getAndThen(UNIFI_API_ENDPOINT + "/site", (data: UnifiSiteType[]) => {
      const sites: SitesType = {};
      data.forEach((site: UnifiSiteType) => {
        sites[site.name] = site.description ? site.description : "";
      });
      setSites(sites);
    });
  }, [setSites]);


  const getDevices = useCallback(() => {
    getAndThen(UNIFI_API_ENDPOINT + "/device", (data: UnifiDeviceType[]) => {
      let devices: UnifiDevicesType = {};
      data.forEach((device: UnifiDeviceType) => {
        if(devices[device.site_name] === undefined) {
          devices[device.site_name] = [];
        }
        devices[device.site_name].push(device);
      });
      setDevices(devices);
    });
  }, [setDevices]);

  useEffect(() => {
    getDevices();
    getSites();
    const interval = setInterval(() => {
      getDevices();
      getSites();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [getDevices, getSites]);

  return <div style={unifiSitesStyle}>
    {devices && sites && Object.keys(devices).map((site) => {
      let error = false;
      devices[site].forEach((device) => {
        if(!device.online) {
          error = true;
        }
      });
      return <UnifiSite key={site} id={site} name={site} description={sites[site]} devices={devices[site]} error={error} />;
    })}
  </div>;
}

export default UnifiSites;
