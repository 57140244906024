import {
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  makeStyles,
} from "@material-ui/core";

interface InputFilterProps {
  onChange: (filterCategory: "content", filterValue: string) => void;
  sectionLabel: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "8px 0"
  },
  formLabel: {
    display: "block",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const InputFilter = ({ onChange, sectionLabel }: InputFilterProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <FormLabel className={classes.formLabel}>{sectionLabel}</FormLabel>
      <FormControl fullWidth>
        <InputLabel>Search Content:</InputLabel>
        <Input
          fullWidth
          onChange={(f) => onChange("content", f.target.value)}
        />
      </FormControl>
    </div>
  );
};

export default InputFilter;
