const timeAgo = (seconds: number) => {
  let minutes = 0;
  if(seconds > 60) {
    minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
  }

  let days = 0;
  if(minutes > 1440) {
    days = Math.floor(minutes / 1440);
    minutes -= days * 1440;
  }

  let hours = 0;
  if(minutes > 60) {
    hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
  }

  let ago = "";
  if(days > 0) {
    if(days > 1) ago += `${days} days, `;
    else ago += `${days} day, `;
  }
  if(hours > 0) {
    if(hours > 1) ago += `${hours} hours, `;
    else ago += `${hours} hour, `;
  }
  if(minutes > 0) {
    if(minutes > 1) ago += `${minutes} minutes, `;
    else ago += `${minutes} minute, `;
  }

  ago += `${seconds} seconds ago`;

  return ago;
}

export { timeAgo };
