import { useHistory } from "react-router-dom";
import { TableRow, TableCell } from '@material-ui/core';
import { CustomerType, UserType } from "../../types";

interface TicketRowPropsType {
  id?: string;
  created_at?: string;
  creator: UserType;
  customer: CustomerType;
  description?: string;
};

const TicketRow = (props: TicketRowPropsType) => {
  const history = useHistory();
  const created_at = props.created_at && new Date(props.created_at);

  return <TableRow onClick={() => history.push("/ticket/" + props.id)} style={{cursor: "pointer"}}>
    <TableCell>{created_at && created_at.toLocaleDateString()}</TableCell>
    <TableCell>{created_at && created_at.toLocaleTimeString()}</TableCell>
    <TableCell>{props.creator && props.creator.name}</TableCell>
    <TableCell>
      {props.customer &&
         (props.customer.type === "Business" ?
           props.customer.business_name :
           props.customer.first_name + " " + props.customer.last_name)
      }
    </TableCell>
    {props.description &&
    <TableCell style={{width: "50%"}}>
      {props.description.split("\n").map((line, i) => <div key={i}>{ line }</div>)}
    </TableCell>
    }
  </TableRow>;
}

export default TicketRow;
