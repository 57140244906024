import ReactJson from "react-json-view";
import { TableRow, TableCell } from "@material-ui/core";
import moment from "moment";
import { LogTypeJson } from "../../types";
import styles from "../../styles.module.scss";

const LogEntry = (props: LogTypeJson) => {

  const time = new Date(props.created_at);

  return (
    <TableRow>
      <TableCell className={styles["log-entry-cell"]}>
        {moment(time).fromNow()}
        <br />
        {time.toLocaleString()}
      </TableCell>
      <TableCell className={styles["log-entry-cell"]}>{props.source}</TableCell>
      <TableCell className={styles["log-entry-cell"]}>
        <ReactJson src={props.headers} collapsed={true} />
      </TableCell>
      <TableCell style={{ width: "60%", overflowWrap: "break-word" }}>
        <ReactJson src={props.content} collapsed={true} />
      </TableCell>
    </TableRow>
  );
};

export default LogEntry;
