import CSS from "csstype";

const cardStyle: CSS.Properties = {
  width: "500px",
  maxWidth: "500px",
  height: "375px",
  float: "left",
  margin: "15px",
}

export { cardStyle };
