import { Button } from "@material-ui/core";
import { UnifiDeviceType, UnifiSiteType } from "../types";
import { UNIFI_CONTROLLER } from "../constants/endpoints";
import CSS from "csstype";

const unifiListContainerStyle: CSS.Properties = {
  width: "50%",
  float: "left",
  textAlign: "center",
}

const unifiListHeaderStyle: CSS.Properties = {
  clear: "both",
  width: "100%"
}

const unifiListContentStyle: CSS.Properties = {
  clear: "both",
  width: "100%",
  marginTop: "15px",
  overflow: "auto",
  height: "140px",
}

const unifiListDeviceStyle: CSS.Properties = {
  textAlign: "right"
}

const onlineStatusStyle: CSS.Properties = {
  color: "green"
}

const offlineStatusStyle: CSS.Properties = {
  color: "red"
}

const upgradableStatusStyle: CSS.Properties = {
  color: "darkkhaki",
};

let unifiTitleStyle: CSS.Properties = {
  float: "right",
  marginRight: "15px",
  marginTop: "5px",
  color: "black"
}

const getDeviceStatus = (device: UnifiDeviceType) => {
  const { online, upgradable } = device;

  if (!online) {
    return <span style={offlineStatusStyle}> - Offline</span>;
  }
  if (upgradable) {
    return <span style={upgradableStatusStyle}> - Upgrade available</span>;
  } 
  return <span style={onlineStatusStyle}> - Online</span>;
}

const UnifiList = (props: UnifiSiteType) => {
  const devices = Object.assign([], props.devices);
  devices.forEach((device: UnifiDeviceType) => {
    const d = new Date(device.last_seen);
    device.online = 1;
    if((Date.now() / 1000) - (d.getTime() / 1000) > 180) {
      device.online = 0;
      unifiTitleStyle = {...unifiTitleStyle, color: "red"};
    }
  });

  devices.sort((a: UnifiDeviceType, b: UnifiDeviceType) => a.online - b.online);

  return <div style={unifiListContainerStyle}>
    <div style={unifiListHeaderStyle}>
      <a href={UNIFI_CONTROLLER + "/manage/site/" + props.name + "/devices/list/1/50"}>
        <Button color="primary" variant="outlined" size="small" style={ {float: 'right'} }>
          Dashboard
        </Button>
      </a>
      <h4 style={unifiTitleStyle}>Unifi</h4>
    </div>
    <div style={unifiListContentStyle}>
    {devices.map((device: UnifiDeviceType) => {
      return <div key={device.id} style={unifiListDeviceStyle}>
        {device.hostname} {getDeviceStatus(device)}
      </div>;
    })}
    </div>
  </div>;
}

export default UnifiList;
