import { useState, useEffect, useCallback, ChangeEvent } from "react";
import { selectUsers } from "../../features/users/usersSlice";
import { useAppSelector } from "../../hooks";
import { Button, Table, TableHead, TableBody, TableRow, TableCell, TextField } from "@material-ui/core";
import { USERS_API_ENDPOINT, TICKETS_API_ENDPOINT } from "../../constants/endpoints";
import { deleteAndThen, getAndThen, postAndThen } from "../../utils/fetchAndThen";
import CSS from "csstype";

interface TicketNoteType {
  id: string,
  created_at: string,
  author: string,
  note: string,
  author_id: string,
  getNotes: Function,
  ticket_id: string,
}

const TicketNote = (props: TicketNoteType) => {
  let d = new Date(props.created_at).toLocaleString();

  const deleteNote = () => {
    deleteAndThen(TICKETS_API_ENDPOINT + "/" + props.ticket_id + "/note/" + props.id, () => {
      props.getNotes();
    });
  }

  return <TableRow>
    <TableCell>
      {props.note.split('\n').map((line, i) => <div key={i}>{line}</div>)}
    </TableCell>
    <TableCell style={ {width: "20%"} }>{d}</TableCell>
    <TableCell style={ {width: "20%"} }>{props.author}</TableCell>
    <TableCell style={ {width: "15%"} }>
      <Button color="secondary" variant="outlined" size="small" onClick={() => deleteNote()}>
        Delete
      </Button>
    </TableCell>
  </TableRow>;
}

interface NewTicketNotePropsType { 
  ticketId: string,
  getNotes: Function,
}

const NewTicketNote = (props: NewTicketNotePropsType) => {
  const [note, setNote] = useState<string>();

  const updateNote = (e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value);

  const addNote = () => {
    const newNote = {
      note: note,
    };

    postAndThen(TICKETS_API_ENDPOINT + "/" + props.ticketId + "/note", newNote, () => {
      setNote("");
      props.getNotes();
    });
  };

  return <TableRow>
    <TableCell colSpan={3}>
      <TextField multiline={true}
        maxRows={5}
        fullWidth
        value={note}
        placeholder="Note"
        onChange={updateNote} />
    </TableCell>
    <TableCell>
      <Button color="primary" variant="outlined" size="small" onClick={() => addNote()}>
        Add
      </Button>
    </TableCell>
  </TableRow>;
}

interface TicketNotesType {
  ticketId: string
}

const ticketNotesStyle: CSS.Properties = {
  width: "45%",
};

const TicketNotes = (props: TicketNotesType) => {
  const [notes, setNotes] = useState<TicketNoteType[]>();
  const users = useAppSelector(selectUsers);

  const getNotes = useCallback(() =>{
    getAndThen(TICKETS_API_ENDPOINT + "/" + props.ticketId + "/note", (notes: TicketNoteType[]) => setNotes(notes));
  }, [props.ticketId, setNotes]);

  useEffect(() => {
    getNotes();
    const interval = setInterval(() => {
      getNotes();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [getNotes]);

  return <Table style={ticketNotesStyle}>
      <TableHead>
        <TableRow>
          <TableCell><h4>Note</h4></TableCell>
          <TableCell><h4>Time</h4></TableCell>
          <TableCell><h4>Author</h4></TableCell>
          <TableCell><h4>Actions</h4></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <NewTicketNote ticketId={props.ticketId} getNotes={getNotes} />
        {notes && users && notes.map((note: TicketNoteType) => <TicketNote key={note.id} {...note} author={users[note.author_id].name} getNotes={getNotes} />)}
      </TableBody>
    </Table>;
}

export default TicketNotes;
