import { Paper, makeStyles, Grid } from "@material-ui/core";
import { getOptions } from "../utils";

import { FilterType, LogElement, LogTypeJson, OptionType } from "../types";
import { headerFilterFields } from "../constants";
import MultiSelectFilter from "../../common/MultiSelectFilter";
import InputFilter from "../../common/InputFilter/InputFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    backgroundColor: '#EEEEEE'
  },
  header: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
}));

interface FilterSectionProps {
  setFilters: (filters: FilterType) => void;
  filters: FilterType;
  logs: LogTypeJson[];
}

const FilterSection = (props: FilterSectionProps) => {
  const { setFilters, filters, logs } = props;
  const classes = useStyles();

  const headers: LogElement[] = [];
  const content: LogElement[] = [];

  logs.forEach((log) => {
    headers.push(log.headers);
    content.push(log.content);
  });
  const headerOptions = getOptions(headers, headerFilterFields);

  const handleSetFilters = (
    type: "headers" | "content",
    newFilters: OptionType | string
  ) => {
    setFilters({
      ...filters,
      [type]: newFilters,
    });
  };

  return (
    <Paper className={classes.root} elevation={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <MultiSelectFilter
            uniqueLabel={"Header Filters"}
            optionFields={headerFilterFields}
            options={headerOptions}
            filters={filters.headers}
            handleChange={(f) => handleSetFilters('headers', f)}
          />
        </Grid>
        <Grid item xs={6}>
          <InputFilter 
            onChange={handleSetFilters}
            sectionLabel="Content Filter"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterSection;
