import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { selectUsers } from "../../features/users/usersSlice";
import { useAppSelector } from "../../hooks";
import { Button } from "@material-ui/core";
import { SITES_API_ENDPOINT, TICKETS_API_ENDPOINT, CUSTOMERS_API_ENDPOINT } from "../../constants/endpoints";
import { getAndThen } from "../../utils/fetchAndThen";
import TicketInfo from "./TicketInfo";
import TicketNotes from "./TicketNotes";
import { CustomerType, CustomerSiteType } from "../../types";
import CSS from "csstype";

const ticketStyle: CSS.Properties = {
  width: "80%",
  paddingTop: "2%",
  margin: "auto"
};

const actionsStyle: CSS.Properties = {
  width: "50%",
  clear: "both",
  margin: "auto",
  marginBottom: "2%"
};

interface TicketType {
  id: string
}

interface TicketDataType {
  id: string,
  description: string[] | string,
  creator_id: string,
  customer_id: string,
  site_id: string
}

const Ticket = (props: TicketType) => {
  const history = useHistory();
  const users = useAppSelector(selectUsers);
  const [ticket, setTicket] = useState<TicketDataType>();
  const [creator, setCreator] = useState<string>();
  const [customerId, setCustomerId] = useState<string>();
  const [customer, setCustomer] = useState<CustomerType>();
  const [siteId, setSiteId] = useState<string>();
  const [site, setSite] = useState<CustomerSiteType>();

  const getTicket = useCallback(() => {
    getAndThen(TICKETS_API_ENDPOINT + "/" + props.id, (ticket: TicketDataType) => {
      setTicket({ ...ticket, description: Array.isArray(ticket.description) ? ticket.description : ticket.description.split("\n") });
      if(ticket.creator_id) {
        const creator = users[ticket.creator_id];
        setCreator(`${creator.name} (${creator.email})`);
      }
      if(ticket.customer_id) setCustomerId(ticket.customer_id);
      if(ticket.site_id) setSiteId(ticket.site_id);
    });
  }, [props.id, users, setCreator]);

  useEffect(() => {
    getTicket();
    const interval = setInterval(() => {
      getTicket();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [getTicket]);

  useEffect(() => {
    if(!customerId) return;
    if(customerId === "0") setCustomer({ id: "0", type: "Business", business_name: ""});
    else getAndThen(CUSTOMERS_API_ENDPOINT + "/" + customerId, (customer: CustomerType) => setCustomer(customer));
  }, [customerId, setCustomer]);

  useEffect(() => {
    if(!siteId) return;
    if(siteId === "0") setSite({ id: "0", customer_id: "0", name: "", address1: "", unifi_name: ""});
    else getAndThen(SITES_API_ENDPOINT + "/" + siteId, (site: CustomerSiteType) =>  setSite(site));
  }, [siteId, setSite]);

  return <div style={ticketStyle}>
    <div style={actionsStyle}>
      <Button color="primary" variant="outlined" size="small" onClick={() => history.push("/ticket/" + props.id + "/edit")}>
        Edit ticket
      </Button>
    </div>
    {ticket && creator && customer && site && <TicketInfo creator={creator} customer={customer} site={site} description={ticket.description} />}
    <TicketNotes ticketId={props.id} />
  </div>
}

export default Ticket;
