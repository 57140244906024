import axios, { Method } from "axios";

const getAndThen = (url: string, setState: Function) => {
  axios
    .get(url)
    .then((res) => setState(res.data))
    .catch((err) => console.log(err));
};

const postAndThen = (url: string, data: any, setState: Function) => {
  putPostAndThen("post", url, data, setState);
};

const putAndThen = (url: string, data: any, setState: Function) => {
  putPostAndThen("put", url, data, setState);
};

const putPostAndThen = (
  method: Method,
  url: string,
  data: any,
  andThen: Function
) => {
  axios({ method, url, data })
    .then((res) => {
      andThen(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

const deleteAndThen = (url: string, andThen: Function) => {
  axios
    .delete(url)
    .then((res) => {
      andThen(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { getAndThen, postAndThen, putAndThen, deleteAndThen, putPostAndThen };
