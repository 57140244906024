import { useState, useEffect, useMemo } from "react";
import { CUSTOMERS_API_ENDPOINT } from "../constants/endpoints";
import { getAndThen } from "../utils/fetchAndThen";
import { CustomerSiteType } from "../types";

interface SitePickerPropsType {
  currentSite: string,
  currentCustomer: string,
  updateSite: Function
};

const SitePicker = (props: SitePickerPropsType) => {
  const chooseSite = useMemo(() => { return { id: "0", customer_id: "0", name: "Choose site...", address1: "", unifi_name: "" }; }, []);
  const [currentSite, setCurrentSite] = useState(props.currentSite);
  const [sites, setSites] = useState<CustomerSiteType[]>([chooseSite]);
  const updateSite = props.updateSite;

  useEffect(() => {
    getAndThen(CUSTOMERS_API_ENDPOINT + "/" + props.currentCustomer + "/site", (new_sites: CustomerSiteType[]) => {
      new_sites.unshift(chooseSite);
      setSites(new_sites);
    });
  }, [props.currentCustomer, chooseSite]);

  useEffect(() => {
    if(currentSite !== props.currentSite) updateSite(currentSite);
  }, [currentSite, props.currentSite, updateSite]);

  return <div>
    <select onChange={(e) => setCurrentSite(e.target.value) } value={currentSite}>
    {sites.map(({ id, name, address1 }) => {
      return <option value={id} key={id}>
        {name} {address1 ? ` - ${address1}` : ""}
      </option>
    })}
    </select>
  </div>;
}

export default SitePicker;
