import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField, Table, TableBody, TableRow, TableCell as MuiTableCell, withStyles } from "@material-ui/core";
import CustomerPicker from "../customers/CustomerPicker";
import SitePicker from "../sites/SitePicker";
import { TICKETS_API_ENDPOINT } from "../constants/endpoints";
import { deleteAndThen, getAndThen, putAndThen } from "../utils/fetchAndThen";
import CSS from "csstype";
import { TicketType } from "../types";

const ticketEditContainerStyle: CSS.Properties = {
  width: "100%",
  clear: "both"
};

const ticketEditHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px"
};

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

interface TicketEditPropsType {
  id: string;
};

const TicketEdit = (props: TicketEditPropsType) => {
  const history = useHistory();
  const [ticket, setTicket] = useState<TicketType>();

  const fetchTicket = useCallback(() => {
    getAndThen(TICKETS_API_ENDPOINT + "/" + props.id, (ticket: TicketType) => setTicket(ticket));
  }, [props.id, setTicket]);

  useEffect(() => {
    fetchTicket();
  }, [fetchTicket]);

  const updateDescription = (e: ChangeEvent<HTMLInputElement>) => setTicket({...ticket!, description: e.target.value });
  const updateCustomer = (id: string) => setTicket({...ticket!, customer_id: id});
  const updateSite = (id: string) => setTicket({...ticket!, site_id: id});    

  const deleteTicket = () => {
    deleteAndThen(TICKETS_API_ENDPOINT + "/" + props.id, () => history.push("/tickets"));
  };

  const saveTicket = () => {
    const url = TICKETS_API_ENDPOINT + "/" + props.id;

    putAndThen(url, ticket, (t: TicketType) => history.push("/ticket/" + t.id));
  };

  return <div style={ticketEditContainerStyle}>
    <div style={ticketEditHeaderStyle}>
      <h2>Edit Ticket</h2>
      <Button color="primary" variant="outlined" size="small" onClick={saveTicket}>
        Save ticket
      </Button>
      <Button color="primary" variant="outlined" size="small" onClick={() => history.push("/ticket/" + props.id)}>
        Cancel edit
      </Button>
      <Button color="secondary" variant="outlined" size="small" onClick={deleteTicket} style={{float: "right"}}>
        Void ticket
      </Button>
    </div>
    <form>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell><h5>Customer</h5></TableCell>
            <TableCell>
              {ticket && <CustomerPicker currentCustomer={ticket.customer_id || "0"} updateCustomer={updateCustomer} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><h5>Site</h5></TableCell>
            <TableCell>
              {ticket && <SitePicker currentCustomer={ticket.customer_id || "0"}
                                     currentSite={ticket.site_id || "0"}
                                     updateSite={updateSite} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><h5>Description</h5></TableCell>
            <TableCell>
              {ticket && <TextField multiline={true}
                                    maxRows={10}
                                    fullWidth
                                    value={ticket.description}
                                    placeholder="Description"
                                    onChange={updateDescription} />}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </form>
  </div>
};

export default TicketEdit;
