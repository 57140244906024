import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  FormLabel,
} from "@material-ui/core";
import { ChangeEvent } from "react";
import { OptionType } from "../../logs/types";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "8px 0",
  },
  formLabel: {
    display: "block",
    fontWeight: "bold",
    textAlign: "center",
  },
  formControl: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultiSelectFilterProps {
  uniqueLabel: string;
  options: OptionType;
  optionFields: string[];
  filters: OptionType;
  handleChange: (filters: OptionType) => void;
}

const MultiSelectFilter = (props: MultiSelectFilterProps) => {
  const { uniqueLabel, options, optionFields, filters, handleChange } = props;
  const classes = useStyles();

  const handleFilterChange = (
    e: ChangeEvent<{ value: unknown }>,
    opt: string
  ) => {
    handleChange({
      ...filters,
      [opt]: e.target.value as string[],
    });
  };

  return (
    <div className={classes.container}>
      <FormLabel className={classes.formLabel}>{uniqueLabel}</FormLabel>
      {optionFields.map((opt) => (
        <FormControl
          className={classes.formControl}
          key={`${uniqueLabel}-${opt}-formcontrol`}
        >
          <InputLabel id={`${uniqueLabel}-${opt}-label`}>{opt}</InputLabel>
          {options[opt] && (
            <Select
              labelId={`${uniqueLabel}-${opt}-label`}
              id={`${uniqueLabel}-${opt}`}
              multiple
              value={filters[opt] || []}
              onChange={(e) => handleFilterChange(e, opt)}
              input={<Input id={`content-chips-${opt}`} />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {options[opt].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      ))}
    </div>
  );
};

export default MultiSelectFilter;
