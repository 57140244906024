import React, { useState, useEffect } from "react";
import { Button, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { CUSTOMERS_API_ENDPOINT } from "../../constants/endpoints";
import { getAndThen } from "../../utils/fetchAndThen";
import User from "./User";
import CSS from "csstype";
import { CustomerType, CustomersType } from "../../types";
import { selectUsers } from "../../features/users/usersSlice";
import { useAppSelector } from "../../hooks";

const usersContainerStyle: CSS.Properties = {
  width: "100%",
  clear: "both"
};

const usersHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px"
};

interface UsersPropsType {
};

const Users = (props: UsersPropsType) => {
  const users = useAppSelector(selectUsers);
  const [customers, setCustomers] = useState<CustomersType>({});

  useEffect(() => {
    getAndThen(CUSTOMERS_API_ENDPOINT, (data: CustomerType[]) => {
      const customers: CustomersType = {};
      data.forEach((customer) => customers[customer.id!] = customer);
      setCustomers(customers);
    });
  }, [setCustomers]);

  return <div style={usersContainerStyle}>
    <div style={usersHeaderStyle}>
      <h2>Users</h2>
      <Button color="primary" variant="outlined" size="small">
        New user
      </Button>
    </div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Customer</TableCell>
          <TableCell>Roles</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(users).map((id) => <User key={id} {...users[id]} customer={customers[users[id].customer_id!]} />)}
      </TableBody>
    </Table>
  </div>;
}

export default Users;
