import React, { useState, useEffect } from "react";
import { CUSTOMERS_API_ENDPOINT } from "../constants/endpoints";
import { getAndThen } from "../utils/fetchAndThen";
import { CustomerType } from "../types";

interface CustomerPickerType {
  currentCustomer: string;
  updateCustomer: Function;
};

const CustomerPicker = (props: CustomerPickerType) => {
  const [currentCustomer, setCurrentCustomer] = useState(props.currentCustomer);
  const [customers, setCustomers] = useState<CustomerType[]>();

  useEffect(() => {
    getAndThen(CUSTOMERS_API_ENDPOINT, (customers: CustomerType[]) => {
      customers.sort((a, b) => {
        const a_name = a.type === "Business" ? a.business_name : a.first_name;
        const b_name = b.type === "Business" ? b.business_name : b.first_name;

        if(a_name && b_name) if(a_name < b_name) return -1;
        if(a_name && b_name) if(a_name > b_name) return 1;
        return 0;
      });

      customers.unshift({ id: "0", type: "Business", business_name: "Choose a customer..." });

      setCustomers(customers);
    });
  }, [setCustomers]);

  return <div>
    <select onChange={(e) => { setCurrentCustomer(e.target.value); props.updateCustomer(e.target.value); }} value={currentCustomer}>
    {customers && customers.map((customer: CustomerType) => {
      return <option value={customer.id} key={customer.id}>
        {customer.type === "Business" ? customer.business_name : customer.first_name + " " + customer.last_name}
      </option>
    })}
    </select>
  </div>;
}

export default CustomerPicker;
