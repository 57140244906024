import { UnifiSiteType } from "../../types";

interface UnifiStatusPropsType {
  site: UnifiSiteType;
};

const onlineStatusStyle = {
  color: "green"
};

const offlineStatusStyle = {
  color: "red"
};

const upgradableStatusStyle = {
  color: "darkkhaki"
};

const getSiteStatus = (offlineDevices: number, upgradable: boolean) => {
  if(offlineDevices) {
    return <span style={offlineStatusStyle}>{offlineDevices} device(s) offline</span>;
  };

  if(upgradable) {
    return <span style={upgradableStatusStyle}>FW upgrades</span>;
  };

  return <span style={onlineStatusStyle}>OK</span>;
};

const UnifiStatus = (props: UnifiStatusPropsType) => {
  let upgradable = false;
  if(props && props.site && props.site.devices) {
    props.site.devices.forEach((device) => {
      if(device.upgradable) upgradable = true;
    });
  }
  return <div>
    Unifi status: {getSiteStatus(props.site.offlineDevices, upgradable)}
  </div>;
};

export default UnifiStatus;
