import { useState } from "react";
import { Route, useHistory } from "react-router-dom";
import Header from "./Header";
import UnifiClients from "./unifi/UnifiClients";
import UnifiSites from "./unifi/UnifiSites";
import Customers from "./customers/Customers";
import Customer from "./customers/Customer";
import CustomerEdit from "./customers/CustomerEdit";
import SiteEdit from "./sites/SiteEdit";
import Tickets from "./tickets/Tickets";
import Ticket from "./tickets/Ticket";
import TicketEdit from "./tickets/TicketEdit";
import Users from "./users/Users";
import User from "./users/User";
import UserEdit from "./users/UserEdit";
import { AZURE_CLIENT_ID, AZURE_TENANT_ID } from "./constants/id";
import * as msal from "@azure/msal-browser";
import EventLog from "./logs/EventLog";
import { setAxiosAuthToken, setInterceptors } from "./utils/axiosConfig";
import { fetchUsers } from "./features/users/usersSlice";
import { useAppDispatch } from "./hooks";

const msalConfig = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: "https://login.microsoftonline.com/" + AZURE_TENANT_ID,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const login = async (setToken: Function, history: any) => {
  if (window.location.pathname !== "/") {
    // Azure will only authenticate to the root URI.
    // Saving current URI to restore after auth.
    localStorage.setItem("redirectURI", window.location.pathname);
    history.push("/");
  }

  let token = null;
  try {
    const loginResponse = await msalInstance.loginPopup({
      scopes: ["api://" + AZURE_CLIENT_ID + "/CRM.login"],
    });
    token = loginResponse.accessToken;
    const tokenExp = (loginResponse.expiresOn || 0).toString();
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExp", tokenExp);
  } catch (e) {
    console.log(e);
  }

  const redirectURI = localStorage.getItem("redirectURI");
  if (token && redirectURI) {
    localStorage.removeItem("redirectURI");
    history.push(redirectURI);
  }
};

const logout = (setToken: Function) => {
  setToken(null);
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExp");
};

const App = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const lsTokenExp = localStorage.getItem("tokenExp");
  const tokenExpNumber = lsTokenExp ? Date.parse(lsTokenExp) : 0;
  const initToken =
    Date.now() < tokenExpNumber ? localStorage.getItem("token") : null;

  const [token, setToken] = useState(initToken);
  token ? setAxiosAuthToken(token) : setAxiosAuthToken();
  setInterceptors();

  dispatch(fetchUsers());

  return (
    <div>
      {token === null ? (
        <Header
          login={() => {
            login(setToken, history);
          }}
        />
      ) : (
        <>
          <Header
            logout={() => {
              logout(setToken);
            }}
          />
          <Route
            exact
            path="/unifi/sites/:site/clients"
            render={(props) => <UnifiClients {...props.match.params} />}
          />
          <Route
            exact
            path="/unifi/sites"
            render={(props) => <UnifiSites {...props.match.params} />}
          />
          <Route
            exact
            path="/customers"
            render={(props) => <Customers {...props.match.params} />}
          />
          <Route
            exact
            path="/customer/:id"
            render={(props) => <Customer {...props.match.params} />}
          />
          <Route
            exact
            path="/customer/:id/edit"
            render={(props) => <CustomerEdit {...props.match.params} />}
          />
          <Route
            exact
            path="/site/:id"
            render={(props) => <SiteEdit {...props.match.params} />}
          />
          <Route
            exact
            path="/tickets"
            render={(props) => <Tickets {...props.match.params} />}
          />
          <Route
            exact
            path="/ticket/:id"
            render={(props) => <Ticket {...props.match.params} />}
          />
          <Route
            exact
            path="/ticket/:id/edit"
            render={(props) => <TicketEdit {...props.match.params} />}
          />
          <Route
            exact
            path="/users"
            render={(props) => <Users {...props.match.params} />}
          />
          <Route
            exact
            path="/user/:id"
            render={(props) => <User {...props.match.params} />}
          />
          <Route
            exact
            path="/user/:id/edit"
            render={(props) => <UserEdit {...props.match.params} />}
          />
          <Route
            exact
            path="/logs"
            render={(props) => <EventLog {...props.match.params} />}
          />
          <Route
            exact
            path="/log/:id"
            render={(props) => <EventLog {...props.match.params} />}
          />
        </>
      )}
    </div>
  );
};

export default App;
