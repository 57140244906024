import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import UnifiList from "../../unifi/UnifiList";
import { CustomerSiteType, TicketType, UnifiSitesType } from "../../types";
import { cardStyle } from "../../styles";
import TicketTable from "./TicketTable";
import CSS from "csstype";
import { postAndThen } from "../../utils/fetchAndThen";
import { TICKETS_API_ENDPOINT } from "../../constants/endpoints";

const buttonStyle: CSS.Properties = {
  marginLeft: "15px",
};

const addressContainerStyle: CSS.Properties = {
  width: "50%",
  float: "left",
};

const addressHeadingStyle: CSS.Properties = {
  marginRight: "15px",
  marginTop: "5px",
};

const addressContentStyle: CSS.Properties = {
  width: "100%",
};

const ticketTableStyle: CSS.Properties = {
  paddingTop: "15px",
  clear: "both",
};

interface CustomerSitePropsType extends CustomerSiteType {
  unifiSites?: UnifiSitesType;
  tickets?: TicketType[];
}

const CustomerSite = (props: CustomerSitePropsType) => {
  const history = useHistory();

  const adjustedCardStyle = props.tickets ? {...cardStyle, height: "750px"} : cardStyle;

  const createNewTicket = () => {
    const ticket = {
      customer_id: props.customer_id,
      site_id: props.id,
      description: "New ticket",
    };

    postAndThen(TICKETS_API_ENDPOINT, ticket, (t: TicketType) => history.push("/ticket/" + t.id + "/edit"));
  };

  return <Card variant="outlined" style={adjustedCardStyle}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2" style={{textAlign: "center"}}>
        <div>{props.name}</div>
        <Button color="primary" variant="outlined" size="small" onClick={() => history.push("/site/" + props.id)} style={buttonStyle}>
          Edit site
        </Button>
      </Typography>
    </CardContent>
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="div">
        <div style={addressContainerStyle}>
          <h4 style={addressHeadingStyle}>Address</h4>
          <div style={addressContentStyle}>
            <div>{props.address1}</div>
            { (props.address2 !== null) ? <div>{props.address2}</div> : "" }
            <div>{ (props.city !== null) ? <span>{props.city},</span> : "" } {props.state} {props.zip}</div>
            <div>{props.country}</div>
          </div>
        </div>
        {props.unifiSites && props.unifi_name && <UnifiList {...props.unifiSites[props.unifi_name]} />}
        <TicketTable tickets={props.tickets} style={ticketTableStyle} createNewTicket={createNewTicket} />
      </Typography>
    </CardContent>
  </Card>;
}

export default CustomerSite;
