import { TicketType, UnifiSiteType } from "../../types";
import UnifiStatus from "./UnifiStatus";
import CSS from "csstype";

interface CustomerSiteBriefType {
  name?: string;
  unifiSite?: UnifiSiteType;
  tickets?: TicketType[];
};

const customerSiteBriefStyle: CSS.Properties = {
  marginBottom: "5px",
};

const openTicketStyle: CSS.Properties = {
  color: "blue",
};

const noTicketStyle: CSS.Properties = {
  color: "green",
};

const CustomerSiteBrief = (props: CustomerSiteBriefType) => {
  const open_tickets = props.tickets ? props.tickets.length : 0;
  return <div style={customerSiteBriefStyle}>
    <strong>{props.name}</strong>
    {props.unifiSite && <UnifiStatus site={props.unifiSite} />}
    <div>Tickets: <span style={open_tickets ? openTicketStyle : noTicketStyle}>{open_tickets}</span></div>
  </div>;
};

export default CustomerSiteBrief;
