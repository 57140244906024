import { TextField } from "@material-ui/core";

interface BusinessEditPropsType {
  businessName: string;
  updateBusinessName: any;
};

const BusinessEdit = ({ businessName, updateBusinessName }: BusinessEditPropsType) => {
  return (
    <TextField
      label="Name"
      value={businessName}
      onChange={updateBusinessName}
    />
  );
};

export default BusinessEdit;
