import { Table, TableBody, TableRow, TableCell as MuiTableCell, withStyles } from "@material-ui/core";
import { CustomerType, CustomerSiteType } from "../../types";
import CSS from "csstype";

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

interface TicketInfoRowType {
  title: string,
  value: string | string[]
}

const TicketInfoRow = (props: TicketInfoRowType) => {
  return <TableRow>
    <TableCell><h4>{props.title}</h4></TableCell>
    {Array.isArray(props.value) ?
    <TableCell>
      {props.value.map((line, i) => {
        return <div key={i}>{line}</div>;
      })}
    </TableCell> :
    <TableCell>{props.value}</TableCell>}
  </TableRow>;
}

interface TicketInfoType {
  customer: CustomerType,
  site: CustomerSiteType,
  creator: string,
  description: string | string[]
}

const ticketInfoStyle: CSS.Properties = {
  width: "45%",
  float: "left",
};

const TicketInfo = (props: TicketInfoType) => {
  let customer: string;
  if(props.customer.type === "Business") {
    customer = `${props.customer.business_name}`;
  } else {
    customer = `${props.customer.first_name} ${props.customer.last_name}`;
  }
  const site = props.site.name + (props.site.address1 ? ` - ${props.site.address1}` : "");
  return <Table style={ticketInfoStyle}>
    <TableBody>
      <TicketInfoRow title="Creator" value={props.creator} />
      <TicketInfoRow title="Customer" value={customer} />
      <TicketInfoRow title="Site" value={site} />
      <TicketInfoRow title="Description" value={props.description} />
    </TableBody>
  </Table>
}

export default TicketInfo;
