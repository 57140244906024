import { useHistory } from "react-router-dom";
import { TableRow, TableCell } from "@material-ui/core";
import { CustomerType, UserRolesType } from "../../types";

interface UserPropsType {
  id: string;
  name: string;
  email?: string;
  customer?: CustomerType;
  roles?: UserRolesType;
};

const User = (props: UserPropsType) => {
  const history = useHistory();
  return <TableRow onClick={() => history.push("/user/" + props.id)}>
    <TableCell>{props.name}</TableCell>
    <TableCell>{props.email}</TableCell>
    <TableCell>
      {props.customer &&
         (props.customer.type === "Business" ?
           props.customer.business_name :
           props.customer.first_name + " " + props.customer.last_name)
      }
    </TableCell>
    <TableCell>
      {props.roles && Object.keys(props.roles).map((id) => <div key={props.roles![id].id}>{props.roles![id].name}</div>)}
    </TableCell>
  </TableRow>;
}

export default User;
