import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import { SITES_API_ENDPOINT } from "../constants/endpoints";
import { getAndThen, putAndThen, deleteAndThen } from "../utils/fetchAndThen";
import UnifiSitePicker from "../unifi/UnifiSitePicker";
import CSS from "csstype";
import { CustomerSiteType } from "../types";

const fieldStyle: CSS.Properties = {
  marginTop: "10px"
};

const siteEditContainerStyle: CSS.Properties = {
  textAlign: "center",
  margin: "auto",
  marginTop: "50px",
  width: "60%"
};

const siteEditHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px"
};

interface SiteEditPropsType {
  id: string;
};

const SiteEdit = (props: SiteEditPropsType) => {
  const history = useHistory();
  const [site, setSite] = useState<CustomerSiteType>();

  const visitCustomerUrl = () => {
    if(site) history.push("/customer/" + site.customer_id);
  };

  const deleteSite = () => {
    deleteAndThen(SITES_API_ENDPOINT + "/" + props.id, visitCustomerUrl);
  };

  const saveSite = () => {
    putAndThen(SITES_API_ENDPOINT + "/" + props.id, site, visitCustomerUrl);
  };

  const fetchSite = useCallback(() => {
    getAndThen(SITES_API_ENDPOINT + "/" + props.id, (site: CustomerSiteType) => {
      setSite(site);
    });
  }, [props.id, setSite]);

  const setUnifiSiteName = (name: string) => {
    setSite({...site!, unifi_name: name});
  };

  useEffect(() => {
    fetchSite();
  }, [fetchSite]);

  const updateField = (e: ChangeEvent<HTMLInputElement>) => {
    const s: CustomerSiteType = {...site!, [e.target.id]: e.target.value};
    setSite(s);
  };

  if(site === undefined) return <></>;
  return <div style={siteEditContainerStyle}>
    <div style={siteEditHeaderStyle}>
      <h1>Edit site</h1>
      <h2>
        {site.customer && (site.customer.type === "Business" ?
         site.customer.business_name :
         site.customer.first_name + " " + site.customer.last_name)}
      </h2>
      <h3>{site.name}</h3>
      <Button color="primary" variant="outlined" size="small" onClick={saveSite}>
        Save site
      </Button>
      <Button color="primary" variant="outlined" size="small" onClick={visitCustomerUrl}>
        Cancel edit
      </Button>
      <Button color="secondary" variant="outlined" size="small" onClick={deleteSite} style={{float: "right"}}>
        Delete site
      </Button>
    </div>
    <div>
      <form style={{marginTop: "30px"}}>
        <TextField id="name"
                   label="Name"
                   value={site.name || ""}
                   fullWidth
                   style={fieldStyle}
                   onChange={updateField}
        />
        <TextField id="address1"
                   label="Address line 1"
                   value={site.address1 || ""}
                   fullWidth
                   style={fieldStyle}
                   onChange={updateField}
        />
        <TextField id="address2"
                   label="Address line 2"
                   value={site.address2 || ""}
                   fullWidth
                   style={fieldStyle}
                   onChange={updateField}
        />
        <TextField id="city"
                   label="City"
                   value={site.city || ""}
                   fullWidth
                   style={fieldStyle}
                   onChange={updateField}
        />
        <TextField id="state"
                   label="State"
                   value={site.state || ""}
                   fullWidth
                   style={fieldStyle}
                   onChange={updateField}
        />
        <TextField id="zip"
                   label="ZIP"
                   value={site.zip || ""}
                   fullWidth
                   style={fieldStyle}
                   onChange={updateField}
        />
        <TextField id="country"
                   label="Country"
                   value={site.country || ""}
                   fullWidth
                   style={fieldStyle}
                   onChange={updateField}
        />
        <UnifiSitePicker unusedSites={site.unused_unifi_sites}
          currentUnifiSite={site.unifi_name} updateUnifiSite={setUnifiSiteName} />
      </form>
    </div>
  </div>;
};

export default SiteEdit;
