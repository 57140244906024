import { useState, useCallback, useEffect } from "react";
import { getAndThen } from "../utils/fetchAndThen";
import { LOGS_API_ENDPOINT } from "../constants/endpoints";
import FilterSection from "./FilterSection";
import LogSection from "./LogSection";
import { filterLogs } from './utils';
import { LogType, LogTypeJson, FilterType } from "./types";
import styles from "./styles.module.scss";

interface EventLogPropsType {
  id?: string;
}

const EventLog = (props: EventLogPropsType) => {
  const [logs, setLogs] = useState<LogTypeJson[]>([]);
  const [filters, setFilters] = useState<FilterType>({ content: '', headers: {}});

  const fetchLogs = useCallback(() => {
    const url = props.id === undefined ? LOGS_API_ENDPOINT : LOGS_API_ENDPOINT + '/' + props.id;
    getAndThen(url, (logs: LogType[]) => {
      const enrichedLogs = logs.map(log => {
        let content: { message?: any } = {};
        try {
          content = JSON.parse(log.content);
        } catch(e) {
          //console.error(e);
          content = { message: log.content};
        }

        let message = {};
        if(content.message !== undefined) {
          try {
            message = JSON.parse(content.message);
          } catch(e) {
            //console.error(e);
            message = content.message;
          }
        }
        content.message = message;

        try {
          message = JSON.parse(content.message);
        } catch(e) {
          //console.error(e);
          message = content.message;
        }
        content.message = message;

        return {
          ...log,
          headers: JSON.parse(log.headers),
          content: content
        }
      });
      setLogs(enrichedLogs);
    });
  }, [props.id]);

  useEffect(() => {
    fetchLogs();

    const interval = setInterval(() => {
      fetchLogs();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchLogs]);

  const filteredLogs = filterLogs(logs, filters);

  return (
    <div className={styles["log-container"]}>
      <div className={styles["log-header"]}>
        <h2>Event log</h2>
      </div>
      <FilterSection
        setFilters={setFilters}
        logs={logs}
        filters={filters}
      />
      <LogSection logs={filteredLogs} />
    </div>
  );
};

export default EventLog;
