import { useCallback, useEffect, useState, ChangeEvent, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { selectUserById, updateUser } from "../features/users/usersSlice";
import { useAppSelector, useAppDispatch } from "../hooks";
import { Button, TextField, Table, TableBody, TableRow, TableCell as MuiTableCell, withStyles } from '@material-ui/core';
import CustomerPicker from '../customers/CustomerPicker';
import { ROLES_API_ENDPOINT, USERS_API_ENDPOINT } from "../constants/endpoints";
import { deleteAndThen, getAndThen, postAndThen, putAndThen } from "../utils/fetchAndThen";
import CSS from "csstype";
import { UserRoleType, UserRolesType } from "../types";

const userEditContainerStyle: CSS.Properties = {
  width: "100%",
  clear: "both"
};

const userEditHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px"
};

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

interface UserEditPropsType {
  id: string;
};

const UserEdit = (props: UserEditPropsType) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => selectUserById(state, props.id));
  const [roles, setRoles] = useState<UserRolesType>();

  const fetchRoles = useCallback(() => {
    getAndThen(ROLES_API_ENDPOINT, (data: UserRoleType[]) => {
      const roles: UserRolesType = {};
      data.forEach((role) => roles[role.id] = role);
      setRoles(roles);
    });
  }, [setRoles]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const saveUser = () => putAndThen(USERS_API_ENDPOINT+ "/" + props.id, user, () => history.push("/user/" + props.id));

  const deleteUser = () => deleteAndThen(USERS_API_ENDPOINT + "/" + props.id, () => history.push("/users"));

  const updateName = (e: ChangeEvent<HTMLInputElement>) => dispatch(updateUser({...user, name: e.target.value}));

  const updateEmail = (e: ChangeEvent<HTMLInputElement>) => dispatch(updateUser({...user, email: e.target.value}));

  const updateCustomer = (customerId: string) => dispatch(updateUser({...user, customer_id: customerId}));

  const addRole = (e: MouseEvent) => postAndThen(USERS_API_ENDPOINT + "/" + props.id + "/role", { id: (e.target as HTMLDivElement).id }, () => {});

  const removeRole = (e: MouseEvent) => deleteAndThen(USERS_API_ENDPOINT + "/" + props.id + "/role/" + (e.target as HTMLDivElement).id, () => {});

  return <div style={userEditContainerStyle}>
    <div style={userEditHeaderStyle}>
      <h2>Editing {user && user.name}</h2>
      <Button color="primary" variant="outlined" size="small" onClick={saveUser}>
        Save user
      </Button>
      <Button color="primary" variant="outlined" size="small" onClick={() => history.push("/user/" + props.id)}>
        Cancel edit
      </Button>
      <Button color="secondary" variant="outlined" size="small" onClick={deleteUser} style={{float: "right"}}>
        Void user
      </Button>
    </div>
    <form>
      {user &&
      <Table>
        <TableBody>
          <TableRow>
            <TableCell><h5>Name</h5></TableCell>
            <TableCell>
              <TextField fullWidth value={user.name} placeholder="Name" onChange={updateName} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><h5>Email</h5></TableCell>
            <TableCell>
              <TextField fullWidth value={user.email} id="email" placeholder="Email address" onChange={updateEmail} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><h5>Customer</h5></TableCell>
            <TableCell>
              <CustomerPicker currentCustomer={user.customer_id || "0"} updateCustomer={updateCustomer} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><h5>Roles</h5></TableCell>
            <TableCell>
              <div>User's roles</div>
              {user && user.roles &&
                Object.keys(user.roles).map((id) => <div key={id} id={user.roles![id]!.id!} onClick={removeRole}>{user.roles![id]!.name}</div>)}
            </TableCell>
            <TableCell>
              <div>Available roles</div>
              {roles && user.roles &&
                Object.keys(roles).map((id) => <div key={id} id={id} onClick={addRole}>{roles[id].name}</div>)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      }
    </form>
  </div>
};

export default UserEdit;
