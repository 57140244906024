import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, TableBody, TableRow, TableCell as MuiTableCell, withStyles } from "@material-ui/core";
import { CUSTOMERS_API_ENDPOINT, USERS_API_ENDPOINT } from "../constants/endpoints";
import { getAndThen } from "../utils/fetchAndThen";
import CSS from "csstype";
import { UserType, CustomerType } from "../types";

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const userContainerStyle: CSS.Properties = {
  width: "100%",
  clear: "both",
};

const userHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px"
};

interface UserPropsType {
  id: string;
};

const User = (props: UserPropsType) => {
  const history = useHistory();
  const [user, setUser] = useState<UserType>();
  const [customer, setCustomer] = useState<CustomerType>({});

  const fetchCustomer = useCallback(() => {
    if(user === undefined) return;
    if(user.customer_id === "0") return;
    getAndThen(CUSTOMERS_API_ENDPOINT + "/" + user.customer_id, setCustomer);
  }, [user, setCustomer]);

  const fetchUser = useCallback(() => {
    if(props.id === undefined) return;
    getAndThen(USERS_API_ENDPOINT + "/" + props.id, (user: UserType) => {
      setUser(user);
    });
  }, [props.id, setUser]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    fetchCustomer();
  }, [user, fetchCustomer]);

  return <div style={userContainerStyle}>
    <div style={userHeaderStyle}>
      <h2>{user && user.name}</h2>
      <Button color="primary" variant="outlined" size="small" onClick={() => history.push("/user/" + props.id + "/edit")}>
        Edit user
      </Button>
    </div>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell><h5>Email</h5></TableCell>
          <TableCell>{user && user.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><h5>Customer</h5></TableCell>
          <TableCell>
            {customer &&
              (customer.type === "Business" ? customer.business_name : customer.first_name + " " + customer.last_name)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell><h5>Roles</h5></TableCell>
          <TableCell>
            {user && user.roles && Object.keys(user.roles).map((id) => <div key={id}>{user.roles![id].name}</div>)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>;
};

export default User;
