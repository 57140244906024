import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { CUSTOMERS_API_ENDPOINT } from "../../constants/endpoints";
import { deleteAndThen, getAndThen, putAndThen } from "../../utils/fetchAndThen";
import BusinessEdit from "./BusinessEdit";
import PersonEdit from "./PersonEdit";
import CSS from "csstype";
import { CustomerType } from "../../types";

const customerEditContainerStyle: CSS.Properties = {
  width: "100%",
  clear: "both",
};

const customerEditHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px",
};

const customerEditContentStyle: CSS.Properties = {
  height: "100%",
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "40px",
  paddingBottom: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

interface CustomerEditPropsType {
  id: string;
};

const CustomerEdit = (props: CustomerEditPropsType) => {
  const history = useHistory();
  const [customer, setCustomer] = useState<CustomerType>();

  const visitCustomerUrl = () => {
    history.push("/customer/" + props.id);
  };

  const saveCustomer = () => {
    putAndThen(
      CUSTOMERS_API_ENDPOINT + "/" + props.id,
      customer,
      visitCustomerUrl
    );
  };

  const deleteCustomer = () => {
    deleteAndThen(CUSTOMERS_API_ENDPOINT + "/" + props.id, () => {
      history.push("/customers");
    });
  };

  const changeCustomerType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCustomer({...customer, type: e.target.value });
  };

  const updateBusinessName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({...customer, business_name: e.target.value});
  };

  const updateFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({...customer, first_name: e.target.value});
  };

  const updateLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({...customer, last_name: e.target.value});
  };

  useEffect(() => {
    getAndThen(CUSTOMERS_API_ENDPOINT + "/" + props.id, (customer: CustomerType) => {
      setCustomer(customer);
    });
  }, [props.id, setCustomer]);

  if (customer === undefined) return <></>;
  const name =
    customer.type === "Business"
      ? customer.business_name
        ? customer.business_name
        : ""
      : (customer.first_name ? customer.first_name : "") +
        " " +
        (customer.last_name ? customer.last_name : "");
  return (
    <div style={customerEditContainerStyle}>
      <div style={customerEditHeaderStyle}>
        <h1>Editing {name}</h1>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={saveCustomer}
          style={{ marginLeft: "15px" }}
        >
          Save customer
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={visitCustomerUrl}
          style={{ marginLeft: "15px" }}
        >
          Cancel edit
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          onClick={deleteCustomer}
          style={{ marginLeft: "90px" }}
        >
          Delete customer
        </Button>
      </div>
      <div style={customerEditContentStyle}>
        <div>
          <form>
            <div>
              <label htmlFor="customer_type" style={{ marginRight: "15px" }}>
                Customer type:
              </label>
              <select
                id="customer_type"
                onChange={changeCustomerType}
                defaultValue={customer.type}
              >
                <option>Business</option>
                <option>Person</option>
              </select>
            </div>
            <div style={{ marginTop: "30px" }}>
              {customer.type === "Business" ? (
                <BusinessEdit businessName={customer.business_name || ""} updateBusinessName={updateBusinessName} />
              ) : (
                <PersonEdit
                  firstName={customer.first_name || ""}
                  lastName={customer.last_name || ""}
                  updateFirstName={updateFirstName}
                  updateLastName={updateLastName}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerEdit;
