import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { UnifiClientType } from "../../types";
import { timeAgo } from "./utils";

const UnifiClient = (props: UnifiClientType) => {
  const style = {...props.style};
  const last_seen = new Date(props.last_seen).getTime() / 1000;
  const now = Math.floor(new Date().getTime() / 1000);
  const seconds = now - last_seen;

  if(seconds < 180) {
    style.color = "green";
  }

  const ago = timeAgo(seconds);

  return <TableRow>
    <TableCell style={style}>{`${props.name} (${props.hostname})`}</TableCell>
    <TableCell style={style}>{props.mac}</TableCell>
    <TableCell style={style}>{ago}</TableCell>
  </TableRow>;
}

export default UnifiClient;
