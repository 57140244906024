import { useState, useEffect } from "react";
import { UNIFI_API_ENDPOINT } from "../constants/endpoints";
import { getAndThen } from "../utils/fetchAndThen";
import { UnifiSiteType, UnifiSitesType } from "../types";

interface UnifiSitePickerPropsType {
  unusedSites: string[];
  currentUnifiSite?: string;
  updateUnifiSite: Function;
};

const UnifiSitePicker = (props: UnifiSitePickerPropsType) => {
  const [currentUnifiSite, setCurrentUnifiSite] = useState<string>(props.currentUnifiSite || "0");
  const [unifiSites, setUnifiSites] = useState<UnifiSitesType>();
  const [unusedSiteNames] = useState(props.unusedSites);

  useEffect(() => {
    const unifiSites: UnifiSitesType = {};
    getAndThen(UNIFI_API_ENDPOINT + "/site", (sites: UnifiSiteType[]) => {
      sites.sort((a, b) => {
        if(a.description && b.description) if(a.description < b.description) return -1;
        if(a.description && b.description) if(a.description > b.description) return 1;
        return 0;
      });

      sites.forEach((site: UnifiSiteType) => unifiSites[site.name] = site);

      setUnifiSites(unifiSites);
    });
  }, [setUnifiSites]);

  const allSites = unifiSites ? Object.keys(unifiSites) : [];
  const sites = unusedSiteNames ? unusedSiteNames : allSites;

  return <div>
    <div>Unifi site:</div>
    <select onChange={(e) => { setCurrentUnifiSite(e.target.value); props.updateUnifiSite(e.target.value); }} value={currentUnifiSite}>
      <option value={0}>No site</option>
      {unifiSites && sites && sites.map((site) => <option value={site} key={site}>{unifiSites[site].description}</option>)}
    </select>
  </div>;
}

export default UnifiSitePicker;
