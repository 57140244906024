const BASE_URL = 'https://api.imperian.systems';
export const USERS_API_ENDPOINT = BASE_URL + '/api/user';
export const CUSTOMERS_API_ENDPOINT = BASE_URL + '/api/customer';
export const TICKETS_API_ENDPOINT = BASE_URL + '/api/ticket';
export const UNIFI_API_ENDPOINT = BASE_URL + '/api/unifi';
export const SITES_API_ENDPOINT = BASE_URL + '/api/site';
export const ROLES_API_ENDPOINT = BASE_URL + '/api/role';
export const LOGS_API_ENDPOINT = BASE_URL + '/api/eventlog';

export const UNIFI_CONTROLLER = "https://unifi.imperian.systems";
