import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { selectUsers } from "../../features/users/usersSlice";
import { useAppSelector } from "../../hooks";
import { Button, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import { TicketType } from "../../types";
import CSS from "csstype";

const ticketInfoStyle: CSS.Properties = {
  backgroundColor: "#EFEFEF",
};

const ticketDescriptionStyle: CSS.Properties = {
  overflow: "hidden",
  height: "60px",
};

const newTicketButtonStyle: CSS.Properties = {
  marginLeft: "10px",
};

const tableBodyStyle: CSS.Properties = {
  overflow: "scroll",
};

interface TicketTablePropsType {
  tickets?: TicketType[];
  style?: CSS.Properties;
  createNewTicket: Function;
};

const TicketTable = (props: TicketTablePropsType) => {
  const history = useHistory();
  const users = useAppSelector(selectUsers);
  return <div style={props.style ? props.style : {}}>
    <h4>
      Tickets
      <Button color="primary" variant="outlined" size="small" style={newTicketButtonStyle} onClick={() => props.createNewTicket()}>
        New Ticket
      </Button>
    </h4>
    {props.tickets &&
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Time</TableCell>
          <TableCell>Creator</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={tableBodyStyle}>
        {props.tickets.map((ticket) => {
          return <Fragment key={ticket.id}>
            <TableRow onClick={() => history.push("/ticket/" + ticket.id)} style={ticketInfoStyle}> 
              <TableCell>{ticket.created_at && (new Date(ticket.created_at)).toLocaleDateString()}</TableCell>
              <TableCell>{ticket.created_at && (new Date(ticket.created_at)).toLocaleTimeString()}</TableCell>
              <TableCell>{ticket.creator_id && users[ticket.creator_id].name}</TableCell>
            </TableRow>
            <TableRow onClick={() => history.push("/ticket/" + ticket.id)}>
              <TableCell colSpan={3} style={ticketDescriptionStyle}>
                {ticket.description?.split("\n").map((line, i) => <div key={i}>{ line }</div>)}
              </TableCell>
            </TableRow>
          </Fragment>;
        })}
      </TableBody>
    </Table>
    }
  </div>;
}

export default TicketTable;
