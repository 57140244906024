import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import CSS from "csstype";

interface LoginType {
  login: Function
}

const Login = (props: LoginType) => {
  return <Button color="secondary" variant="contained" onClick={() => props.login()}>Login</Button>;
}

interface MenuButtonType {
  children: string;
}

const menuButtonStyle: CSS.Properties = {
  marginRight: "5px"
}

const MenuButton = (props: MenuButtonType) => {
  return <Button color="primary" variant="contained" style={menuButtonStyle}>
    {props.children}
  </Button>;
}

interface MenuType {
  logout: Function
}

const menuStyle: CSS.Properties = {
  float: "left",
  marginLeft: "5%"
}

const Menu = (props: MenuType) => {
  return <div>
    <menu style={menuStyle}>
      <Link to="/logs"><MenuButton>Logs</MenuButton></Link>
      <Link to="/unifi/sites"><MenuButton>Unifi</MenuButton></Link>
      <Link to="/customers"><MenuButton>Customers</MenuButton></Link>
      <Link to="/tickets"><MenuButton>Tickets</MenuButton></Link>
      <Link to="/users"><MenuButton>Users</MenuButton></Link>
    </menu>
    <Button color="secondary" variant="contained" onClick={() => props.logout()} style={ {float: "right", marginRight: "10%"} }>Logout</Button>
  </div>;
}

const headerStyle: CSS.Properties = {
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  padding: "10px"
}

interface HeaderType {
  login?: Function,
  logout?: Function
}

const Header = (props: HeaderType) => {
  return <header style={headerStyle}>
    <h1>Imperian Customer Relations Manager</h1>
    {props.login ?
      <Login login={() => { if(props.login) props.login() }} /> :
      <Menu logout={() => { if(props.logout) props.logout() }} />
    }
  </header>;
};

export default Header;
