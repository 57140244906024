import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { selectUsers } from "../../features/users/usersSlice";
import { useAppSelector } from "../../hooks";
import { Button, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { CUSTOMERS_API_ENDPOINT, TICKETS_API_ENDPOINT } from "../../constants/endpoints";
import { getAndThen, postAndThen } from "../../utils/fetchAndThen";
import { CustomerType, CustomersType, TicketType } from "../../types"; 
import CSS from "csstype";
import TicketRow from "./TicketRow";

const ticketsContainerStyle: CSS.Properties = {
  width: "100%",
  clear: "both"
};

const ticketsHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px"
};

interface TicketsPropsType {
};

const Tickets = (props: TicketsPropsType) => {
  const history = useHistory();
  const users = useAppSelector(selectUsers);
  const [tickets, setTickets] = useState<TicketType[]>();
  const [customers, setCustomers] = useState<CustomersType>();

  const addTicket = () => {
    const ticket = {
      description: "New ticket",
      customer_id: 0,
      employee_id: 0
    };

    postAndThen(TICKETS_API_ENDPOINT, ticket, (ticket: TicketType) => history.push("/ticket/" + ticket.id));
  };

  const fetchCustomers = useCallback(() => {
    getAndThen(CUSTOMERS_API_ENDPOINT, (data: CustomerType[]) => {
      const customers: CustomersType = {};
      data.forEach((customer) => customers[customer.id!] = customer);
      setCustomers(customers);
    });
  }, [ setCustomers]);

  const fetchTickets = useCallback(() => {
    getAndThen(TICKETS_API_ENDPOINT, (tickets: TicketType[]) => setTickets(tickets));
  }, [setTickets]);

  useEffect(() => {
    fetchCustomers();
    fetchTickets();

    const interval = setInterval(() => {
      fetchCustomers();
      fetchTickets();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchCustomers, fetchTickets]);

  return <div style={ticketsContainerStyle}>
    <div style={ticketsHeaderStyle}>
      <h2>Tickets</h2>
      <Button onClick={addTicket}  color="primary" variant="outlined" size="small">
        New ticket
      </Button>
    </div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Time</TableCell>
          <TableCell>Creator</TableCell>
          <TableCell>Customer</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tickets && customers && users &&
          tickets.map((ticket) => {
             if(ticket) return <TicketRow key={ticket.id} {...ticket}
                                          creator={users[ticket.creator_id || "0"]}
                                          customer={customers[ticket.customer_id || "0"]} />;
               return <></>;
             }
          )
        }
      </TableBody>
    </Table>
  </div>
};

export default Tickets;
