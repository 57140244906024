import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import LogEntry from "./LogEntry";
import { LogTypeJson } from "../types";
import styles from "../styles.module.scss";

interface LogSectionProps {
  logs: LogTypeJson[];
}

const LogSection = (props: LogSectionProps) => {
  const { logs } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={styles["log-entry-cell"]}>Timestamp</TableCell>
          <TableCell className={styles["log-entry-cell"]}>IP address</TableCell>
          <TableCell className={styles["log-entry-cell"]}>Headers</TableCell>
          <TableCell style={{ width: "60%" }}>Content</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map((entry) => (
          <LogEntry key={entry.id} {...entry} />
        ))}
      </TableBody>
    </Table>
  );
};

export default LogSection;
