import { useHistory } from "react-router-dom";
import { Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import { UnifiDeviceType } from "../../types";
import CSS from "csstype";
import { cardStyle } from "../../styles";

const onlineStatusStyle: CSS.Properties = {
  color: "green",
};

const offlineStatusStyle: CSS.Properties = {
  color: "red",
};

const upgradableStatusStyle: CSS.Properties = {
  color: "darkkhaki",
};

interface UnifiSitePropsType {
  id: string;
  name: string;
  error: boolean;
  description: string;
  devices: UnifiDeviceType[];
};

const getDeviceStatus = (device: UnifiDeviceType) => {
  const { online, upgradable } = device;

  if (!online) {
    return <span style={offlineStatusStyle}> - Offline</span>;
  }
  if (upgradable) {
    return <span style={upgradableStatusStyle}> - Upgrade available</span>;
  } 
  return <span style={onlineStatusStyle}> - Online</span>;
}

const UnifiSite = (props: UnifiSitePropsType) => {
  const history = useHistory();
  return <Card style={cardStyle} variant="outlined">
      <CardActionArea onClick={() => history.push(`/unifi/sites/${props.id}/clients`)}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" style={ {textAlign: 'center'} }>
            <span style={ { color: (props.error ? 'red' : 'black') } }>{props.description}</span>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="div">
            {props.devices.map((device, index) => {
              return <div key={index} style={ {textAlign: 'right'} }>
                {device.hostname} {getDeviceStatus(device)}
              </div>;
            })}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>;
}

export default UnifiSite;
