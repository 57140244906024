import { useHistory } from "react-router-dom";
import { Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import { cardStyle } from "../../styles";
import CustomerSiteBrief from "./CustomerSiteBrief";
import { CustomerSiteType, SiteTicketsType, TicketType, UnifiSitesType } from "../../types";
import CSS from "csstype";

const ticketStatusStyle: CSS.Properties = {
  color: "blue"
};

interface CustomerBriefPropsType {
  id?: string;
  type?: string;
  business_name?: string;
  first_name?: string;
  last_name?: string;
  sites?: CustomerSiteType[];
  unifiSites: UnifiSitesType;
  tickets?: TicketType[];
};

const CustomerBrief = (props: CustomerBriefPropsType) => {
  const history = useHistory();
  const open_tickets = props.tickets ? props.tickets.length : 0;
  const siteTickets: SiteTicketsType = {};
  if(props.tickets) {
    props.tickets.forEach((ticket) => {
      if(siteTickets[ticket.site_id!] === undefined) {
        siteTickets[ticket.site_id!] = [];
      }
      siteTickets[ticket.site_id!].push(ticket);
    });
  }
  return <Card style={cardStyle} variant="outlined">
    <CardActionArea onClick={() => history.push("/customer/" + props.id)}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2" style={{textAlign: "center"}}>
          {props.type === "Business" ? props.business_name : props.first_name + " " + props.last_name}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="div">
        <div style={{ float: "left" }}>
          {props.sites && props.unifiSites && props.sites.map((site) => {
            const unifiSite = (props.unifiSites && site.unifi_name && props.unifiSites[site.unifi_name]) || undefined;
            return <CustomerSiteBrief key={site.id} {...site} unifiSite={unifiSite} tickets={siteTickets[site.id!] || []} />
          })}
        </div>
        <div style={{ float: "right" }}>
          {open_tickets !== 0 && <div style={ticketStatusStyle}>{open_tickets} open ticket{open_tickets > 1 && "s"}</div>}
        </div>
      </Typography>
    </CardContent>
  </Card>;
}

export default CustomerBrief;
