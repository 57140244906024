import { FilterType, LogElement, LogTypeJson, OptionType } from "./types";

const processField = (field: string, addItem: (item: string) => void) => {
  if (Array.isArray(field)) {
    field.forEach((item: string) => {
      addItem(item);
    });
  } else {
    addItem(field);
  }
};

export const getOptions = (
  logElements: LogElement[],
  filterFields: string[]
) => {
  return logElements.reduce((acc: OptionType, cur: LogElement) => {
    let newAcc = { ...acc };
    filterFields.forEach((field) => {
      const addItem = (item: string) => {
        if (newAcc[field]) {
          if (newAcc[field].indexOf(item) === -1) {
            newAcc = { ...newAcc, [field]: [...acc[field], item] };
          }
        } else {
          newAcc = { ...acc, [field]: [item] };
        }
      };

      if (cur[field]) {
        processField(cur[field], addItem);
      }
    });
    return newAcc;
  }, {});
};

const doesPassFilter = (logVal: string | string[], filter: string[]) => {
  if (filter && filter.length > 0) {
    if (!logVal) {
      return false;
    }
    let newVal;
    if (!Array.isArray(logVal)) {
      newVal = [logVal];
    } else {
      newVal = [...logVal];
    }
    let passing = false;
    newVal.forEach(val => {
      if (filter.indexOf(val) !== -1) {
        passing = true;
      }
    });
    return passing;
  }
  return true;
};

export const filterLogs = (logs: LogTypeJson[], filters: FilterType) => {
  const { content, headers } = filters;
  const filteredLogs: LogTypeJson[] = [];

  logs.forEach((log, i) => {
    let include = true;

    // Check content filter
    if (!JSON.stringify(log.content).includes(content)) {
      include = false;
    }

    // Check header filters
    Object.keys(headers).forEach((key) => {
      if (!doesPassFilter(log.headers[key], headers[key])) {
        include = false;
      }
    });

    if (include) {
      filteredLogs.push(log);
    }
  });

  return filteredLogs;
};
