import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import { CUSTOMERS_API_ENDPOINT, UNIFI_API_ENDPOINT } from "../../constants/endpoints";
import { getAndThen, postAndThen } from "../../utils/fetchAndThen";
import CustomerBrief from "./CustomerBrief";
import { CustomerType, UnifiSiteType, UnifiSitesType } from "../../types";
import CSS from "csstype";

interface CustomersPropsType {
};

const customersContainerStyle: CSS.Properties = {
  width: "100%",
  clear: "both"
};

const customersHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px"
};

const Customers = (props: CustomersPropsType) => {
  const history = useHistory();
  const [customers, setCustomers] = useState<CustomerType[]>();
  const [unifiSites, setUnifiSites] = useState<UnifiSitesType>();

  const addCustomer = () => {
    const c = {
      type: "Business",
      business_name: "New customer"
    };

    postAndThen(CUSTOMERS_API_ENDPOINT, c, (customer: CustomerType) => history.push("/customer/" + customer.id));
  };

  const fetchUnifiSites = useCallback(() => {
    getAndThen(UNIFI_API_ENDPOINT + "/site", (data: UnifiSiteType[]) => {
      const sites: UnifiSitesType = {};
      data.forEach((site) => {
        sites[site.name] = site;
      });
      setUnifiSites(sites);
    });
  }, [setUnifiSites]);

  const fetchCustomers = useCallback(() => {
    getAndThen(CUSTOMERS_API_ENDPOINT, (customers: CustomerType[]) => {
      customers.sort((a, b) => {
        const a_name = a.type === "Business" ? a.business_name : a.first_name;
        const b_name = b.type === "Business" ? b.business_name : b.first_name;

        if(a_name && b_name) if(a_name < b_name) return -1;
        if(a_name && b_name) if(a_name > b_name) return 1;
        return 0;
      });

      setCustomers(customers)
    });
  }, [setCustomers]);

  useEffect(() => {
    fetchUnifiSites();
    fetchCustomers();

    const interval = setInterval(() => {
      fetchCustomers();
      fetchUnifiSites();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchCustomers, fetchUnifiSites]);

  return <div style={customersContainerStyle}>
    <div style={customersHeaderStyle}>
      <h2>Customers</h2>
      <Button onClick={() => addCustomer()}  color="primary" variant="outlined" size="small">
        New customer
      </Button>
    </div>
    {customers && unifiSites && customers.map((customer: CustomerType) => <CustomerBrief key={customer.id} {...customer} unifiSites={unifiSites} />)}
  </div>
};

export default Customers;
