import { TextField } from "@material-ui/core";

interface PersonEditPropsType {
  firstName: string;
  lastName: string;
  updateFirstName: any;
  updateLastName: any;
};

const PersonEdit = ({ firstName, lastName, updateFirstName, updateLastName }: PersonEditPropsType) => {
  return (
    <>
      <TextField
        id="first_name"
        label="First name"
        value={firstName}
        onChange={updateFirstName}
      />
      <TextField
        id="last_name"
        label="Last name"
        value={lastName}
        onChange={updateLastName}
      />
    </>
  );
};

export default PersonEdit;
