import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersType, UserType } from "../../types";
import { RootState } from "../../store";
import { USERS_API_ENDPOINT } from "../../constants/endpoints";
import { get } from "../../utils/fetch";
import axios, { AxiosResponse } from "axios";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async () => {
    if(axios.defaults.headers.common['Authorization'] === undefined) return [];
    const response: AxiosResponse<UserType[]> = await get(USERS_API_ENDPOINT);
    return response.data;
  }
);

const initialState: UsersType = {};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    updateUser(state, action: PayloadAction<UserType>) {
      state[action.payload.id] = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      const userlist: UsersType = {};
      action.payload.forEach((user) => userlist[user.id!] = user);
      return userlist;
    })
  },
});

export const selectUsers = (appState: RootState) => appState.users;
export const selectUserById = (appState: RootState, id: string) => appState.users[id];
export const { updateUser } = usersSlice.actions;

export default usersSlice.reducer;
