import React, { useState, useCallback, useEffect } from "react";
import { getAndThen } from "../../utils/fetchAndThen";
import { UNIFI_API_ENDPOINT } from "../../constants/endpoints";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import CSS from "csstype";
import { UnifiClientType, UnifiSiteType } from "../../types";
import UnifiClient from "./UnifiClient";

const oddRowStyle: CSS.Properties = {
  backgroundColor: "#E7E7E7"
};

interface UnifiClientsPropsType {
  site: string
}

const UnifiClients = (props: UnifiClientsPropsType) => {
  const [clients, setClients] = useState<UnifiClientType[]>([]);
  const [site, setSite] = useState<UnifiSiteType>();

  const getClients = useCallback(() => {
    getAndThen(UNIFI_API_ENDPOINT + "/client/" + props.site, (clients: UnifiClientType[]) => {
      clients.sort((a, b) => {
        let last_a = new Date(a.last_seen).getTime() / 1000;
        let last_b = new Date(b.last_seen).getTime() / 1000;
        return last_b - last_a;
      });
      setClients(clients);
    });
  }, [props.site]);

  useEffect(() => {
    getClients();

    getAndThen(UNIFI_API_ENDPOINT + "/site/" + props.site, (site: UnifiSiteType) => setSite(site));

    const interval = setInterval(() => {
      getClients();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [getClients, setSite, props.site]);

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell colSpan={3}><h1>{site && site.description}</h1></TableCell>
      </TableRow>
      <TableRow>
        <TableCell><h4>Name (hostname)</h4></TableCell>
        <TableCell><h4>MAC</h4></TableCell>
        <TableCell><h4>Last seen</h4></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {clients.map((client, i) => {
        const style = (i % 2) ? {} : oddRowStyle;
        return <UnifiClient key={client.id} {...client} style={style}/>
      })}
    </TableBody>
  </Table>;
}

export default UnifiClients;
