import axios from 'axios';
import logoutAndRedirect from './logoutAndRedirect';

export const setAxiosAuthToken = (token?: string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else if (axios.defaults.headers.common['Authorization']) {
    delete axios.defaults.headers.common['Authorization'];
  }
  axios.defaults.headers.common['Content-Type'] = "application/json";
  axios.defaults.headers.common['Accept'] = "application/json";
};

export const setInterceptors = () => {
  axios.interceptors.response.use(res => res,
    error => {
      const { status } = error.response;
      if (status === 401) {
        logoutAndRedirect();
      }
      return Promise.reject(error);
    });
}