import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { 
  CUSTOMERS_API_ENDPOINT,
  SITES_API_ENDPOINT,
  TICKETS_API_ENDPOINT,
  UNIFI_API_ENDPOINT,
} from "../../constants/endpoints";
import { getAndThen, postAndThen } from "../../utils/fetchAndThen";
import CustomerSite from "./CustomerSite";
import TicketTable from "./TicketTable";
import { CustomerType, SiteTicketsType, TicketType, UnifiSiteType, UnifiSitesType } from "../../types";
import { cardStyle } from "../../styles";
import CSS from "csstype";

const customerContainerStyle: CSS.Properties = {
  width: "100%", 
  clear: "both",
};

const customerHeaderStyle: CSS.Properties = {
  width: "80%",
  textAlign: "center",
  margin: "auto",
  paddingTop: "10px",
  paddingBottom: "10px",
};

const buttonStyle: CSS.Properties = {
  marginLeft: "15px",
};

interface CustomerPropsType {
  id: string;
};

const Customer = (props: CustomerPropsType) => {
  const history = useHistory();
  const [customer, setCustomer] = useState<CustomerType>();
  const [unifiSites, setUnifiSites] = useState<UnifiSitesType>();
  const [siteTickets, setSiteTickets] = useState<SiteTicketsType>();

  const createNewSite = () => {
    const site = {
      customer_id: props.id,
      name: "New site"
    };

    postAndThen(SITES_API_ENDPOINT, site, fetchCustomer);
  };

  const createNewTicket = () => {
    const ticket = {
      customer_id: customer!.id,
      description: "New ticket",
    };

    postAndThen(TICKETS_API_ENDPOINT, ticket, (t: TicketType) => history.push("/ticket/" + t.id + "/edit"));
  };

  const fetchCustomer = useCallback(() => {
    getAndThen(CUSTOMERS_API_ENDPOINT + "/" + props.id, (customer: CustomerType) => {
      const siteTickets: SiteTicketsType = {};
      if(customer.tickets) {
        customer.tickets.forEach((ticket) => {
          if(siteTickets[ticket.site_id!] === undefined) {
            siteTickets[ticket.site_id!] = [];
          }
          siteTickets[ticket.site_id!].push(ticket);
        });
      }
      setCustomer(customer);
      setSiteTickets(siteTickets);
    });
  }, [props.id, setCustomer, setSiteTickets]);

  const fetchUnifiSites = useCallback(() => {
    getAndThen(UNIFI_API_ENDPOINT + "/site", (data: UnifiSiteType[]) => {
      const sites: UnifiSitesType = {};
      data.forEach((site: UnifiSiteType) => {
        sites[site.name] = site;
      });
      setUnifiSites(sites);
    });
  }, []);

  useEffect(() => {
    fetchCustomer();
    fetchUnifiSites();

    const interval = setInterval(() => {
      fetchCustomer();
      fetchUnifiSites();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [props.id, setCustomer, fetchCustomer, fetchUnifiSites]);

  if(customer === undefined) return  <></>;
  return <div style={customerContainerStyle}>
    <div style={customerHeaderStyle}>
      <h1>{ customer.type === "Business" ? customer.business_name : customer.first_name + " " + customer.last_name}</h1>
      <Button color="primary" variant="outlined" size="small" onClick={() => history.push(window.location.href + "/edit")} style={buttonStyle}>
         Edit customer
      </Button>
      <Button color="primary" variant="outlined" size="small" onClick={() => createNewSite()} style={buttonStyle}>
         New site
      </Button>
    </div>
    {siteTickets && <Card variant="outlined" style={cardStyle}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2" style={{textAlign: "center"}}>
        </Typography>
      </CardContent>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="div">
          <TicketTable tickets={siteTickets["0"]} createNewTicket={createNewTicket} />
        </Typography>
      </CardContent>
    </Card>}
    {customer.sites && unifiSites && siteTickets &&
      customer.sites.map((site) => <CustomerSite key={site.id} {...site} unifiSites={unifiSites} tickets={siteTickets[site.id!]} />)}
  </div>
};

export default Customer;
